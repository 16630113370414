// Timeline - Pink
.vertical-timeline-element--education {
  &.main {
    .vertical-timeline-element-content {
      background: rgb(233, 30, 99);
      color: #fff;
      box-shadow: 0 3px 0 white;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid rgb(233, 30, 99);
    }
  }
  .vertical-timeline-element-content {
    box-shadow: 0 3px 0 rgb(233, 30, 99);
  }

  .vertical-timeline-element-icon {
    background: rgb(233, 30, 99);
    color: rgb(255, 255, 255);
  }
}

// Work - Blue
.vertical-timeline-element--work {
  &.main {
    .vertical-timeline-element-content {
      background: rgb(33, 150, 243);
      box-shadow: 0 3px 0 white;
      color: #fff;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid rgb(33, 150, 243);
    }
  }
  .vertical-timeline-element-content {
    box-shadow: 0 3px 0 rgb(33, 150, 243);
  }

  .vertical-timeline-element-icon {
    background: rgb(33, 150, 243);
    color: rgb(255, 255, 255);
  }
}

// All
.vertical-timeline-element-content {
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  div {
    margin-top: 1rem;
  }
  p {
    font-size: 1.25rem;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .vertical-timeline-element-date {
    color: #333;
    font-weight: bold;
  }
}