#skills {
  background: #e3e3e3;

  padding-bottom: 200px;

  @include respond(tab-land) {
    padding: 5rem 0rem;
  }

  .skillbar-title {
    // width: auto;
    span {
      padding: 0;
    }
  }
}